import React, {useEffect, useMemo} from "react";
import {ColumnDef} from "@tanstack/react-table";
import TableWithPages from "../../../components/TableWithPages";
import {int} from "@zxing/library/es2015/customTypings";
import {dateToString} from "../../../utils/Utils";
import {Button, Form, InputGroup, Modal, Stack} from "react-bootstrap";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useNavigate} from "react-router-dom";
import {captureException} from "@sentry/react";


const StripeCustomersModal = (props: {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    assign: (name: string, email: string) => void;
}) => {

    const {apiToken, logOut} = useUserAuth();
    const navigate = useNavigate();
    const [customers, setCustomers] = React.useState([]);
    // const [lastCustomerId, setLastCustomerId] = React.useState<string>();
    const [limit, setLimit] = React.useState<number>(100);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    // const [selectedCustomer, setSelectedCustomer] = React.useState<{ name: string, email: string }>();
    const [email, setEmail] = React.useState<string>();
    const [name, setName] = React.useState<string>();

    useEffect(() => {
        if (!apiToken) {
            logOut().then(() => navigate("/login"));
        }

        fetch('https://api.s-wipe.io/api/customers', {
            headers: {
                Authorization: `Bearer ${apiToken}`,
                "Content-Type": "application/json"
            },
            method: 'GET',
        }).then((response) => {
            response.json().then((result) => {
                if (result.success) {
                    setCustomers(result.data);
                }
            });
        }).catch((error) => {
            const msg = `Cannot retrieve Stripe customers: ${error}`;
            console.error(msg, {cause: error});
            captureException(msg);
        });
    }, [limit, apiToken, logOut, navigate]);  // lastCustomerId, limit

    useEffect(() => {
        if (selectedIndex >= 0 && selectedIndex < customers.length) {
            const customer = customers[selectedIndex];
            setName(customer.name);
            setEmail(customer.email);
        }
    }, [customers, props, selectedIndex]);

    useEffect(() => {
        // Set selected index to -1 if name and email don't match any Stripe customer
        for (const customer of customers) {
            if (customer.name === name && customer.email === email) return;
        }
        setSelectedIndex(-1);
    }, [name, email, customers]);

    //TODO Clear name and email on setShowModal(true)

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'id',
            header: '',
            cell: info => <input type="radio" name="customerId" readOnly
                                 checked={parseInt(info.row.id) === selectedIndex}/>,
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'created',
            header: 'Created On',
            cell: info => dateToString(new Date(1000 * (info.getValue() as int)))
        }
    ], [selectedIndex]);

    return (
        <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Select Stripe Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="small">
                <Form className="mb-1 mb-md-3">
                    <Stack gap={1}>
                        <InputGroup>
                            <InputGroup.Text>Email:</InputGroup.Text>
                            <Form.Control type="email" placeholder="Enter customer email or select below" value={email}
                                          onChange={(e) => setEmail(e.target.value)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Name:</InputGroup.Text>
                            <Form.Control type="text" placeholder="Enter customer name or select below" value={name}
                                          onChange={(e) => setName(e.target.value)}/>
                        </InputGroup>
                    </Stack>
                </Form>
                <TableWithPages data={customers} columns={columns} setSelectedIndex={setSelectedIndex}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setShowModal(false)}>Cancel</Button>
                <Button disabled={!email || !name} onClick={() => {
                    props.assign(name, email);
                    props.setShowModal(false);
                }}>Assign</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StripeCustomersModal;
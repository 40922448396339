import React, {useState} from "react";
import {Button, ButtonGroup, Form, Stack, Table} from 'react-bootstrap';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel, getPaginationRowModel, getSortedRowModel,
    Header,
    HeaderGroup,
    PaginationState, Row, SortingState,
    useReactTable
} from '@tanstack/react-table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight, faArrowUp, faArrowDown} from "@fortawesome/free-solid-svg-icons";


const TableWithPages = ({data, columns, initialSorting, className, setSelectedIndex}: {
    data: any[],
    columns: ColumnDef<any>[],
    initialSorting?: {id: string, desc: boolean},
    className?: string,
    setSelectedIndex?: (x: number) => void,
}) => {

    const [pagination, setPagination] = useState<PaginationState>({pageIndex: 0, pageSize: 10});
    const [sorting, setSorting] = useState<SortingState>(initialSorting ? [initialSorting] : []);

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        state: {pagination, sorting}
    });

    return (
        <div className="overflow-x-auto">
            <Table className={"table-sm " + className} striped hover>
                <thead>
                {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: Header<any, any>) => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    <div {...{
                                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                        onClick: header.column.getToggleSortingHandler()
                                    }}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: <FontAwesomeIcon icon={faArrowDown} className="ms-1" color="grey"/>,
                                            desc: <FontAwesomeIcon icon={faArrowUp} className="ms-1" color="grey"/>
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row: Row<any>) => (
                    <tr key={row.id} onClick={() => {
                        if (!!setSelectedIndex) {
                            setSelectedIndex(parseInt(row.id));
                        }
                    }}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
            <Stack direction="horizontal" className="justify-content-between small" gap={3}>
                <ButtonGroup className="btn-group-sm">
                    <Button variant="outline-dark" onClick={() => table.firstPage()}
                            disabled={!table.getCanPreviousPage()}>
                        <FontAwesomeIcon icon={faAnglesLeft}/>
                    </Button>
                    <Button variant="outline-dark" onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </Button>
                    <Button variant="outline-dark" onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </Button>
                    <Button variant="outline-dark" onClick={() => table.lastPage()}
                            disabled={!table.getCanNextPage()}>
                        <FontAwesomeIcon icon={faAnglesRight}/>
                    </Button>
                </ButtonGroup>

                <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>

                <Form.Select className="border-dark" size="sm" style={{width: 110}}
                             value={table.getState().pagination.pageSize}
                             onChange={(e) => {table.setPageSize(Number(e.target.value))}}>
                    {[5, 10].map(pageSize => (
                        <option key={pageSize} value={pageSize}>Show {pageSize}</option>
                    ))}
                </Form.Select>
            </Stack>
        </div>
    )
}

export default TableWithPages;
import {captureException} from "@sentry/react";

export async function searchBarcodes(apiToken: string, searchText: string) {
    if (!apiToken) {
        throw new Error('Missing API Token');
    }

    const searchParams = new URLSearchParams({text: searchText});
    try {
        const response = await fetch(`https://api.s-wipe.io/api/barcodes/search?${searchParams}`, {
            headers: {
                Authorization: `Bearer ${apiToken}`,
                "Content-Type": "application/json"
            },
            method: 'GET',
        });

        const json = await response.json();
        if (json.success) {
            return json.data;
        }
    } catch (error) {
        const msg = `Cannot search barcodes with "${searchText}": ${error}`;
        console.error(msg, {cause: error});
        captureException(msg);
    }

    return [];
}
import React from 'react';
import "react-barcode-scanner/polyfill"
import Button from "react-bootstrap/Button";
import {Form, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import BarcodeScanner from "../../../components/BarcodeScanner";


const Scan = ({next, barcode, setBarcode}) => {

    const {updateScanData} = useUserAuth();
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [barcodeText, setBarcodeText] = React.useState('');

    function handleClick() {
        try {
            updateScanData(barcode, "barcode", barcode, "date", new Date());
            next();
        } catch (error) {
            console.error(error);
        }
    }

    function handleScan(barcode: string) {
        if (barcode !== null) {
            setBarcode(barcode, () => {
                setShowSuccessMessage(true);
                setBarcodeText(barcode);
            });
        }
    }

    return (
        <>
            <h3 className="flex-grow-1">Scan Barcode on S-Wipe tube</h3>
            <Stack className="justify-content-center flex-grow-1 gap-md-3" gap={1}>
                <BarcodeScanner handleScan={handleScan} />
                <h6 hidden={!showSuccessMessage}>
                    Barcode scanned successfully. Press <Link onClick={handleClick}>next</Link> to continue.
                </h6>
            </Stack>
            <div className="flex-grow-1">
                <Stack direction="horizontal" gap={1}>
                    <Form.Label className="small">Scan or enter the barcode manually</Form.Label>
                    <Form.Control type="number" value={barcodeText ?? ''}
                                  onChange={(e) => {
                                      setBarcodeText(e.currentTarget.value);
                                      setBarcode(e.currentTarget.value, () => setShowSuccessMessage(true));
                                  }}/>
                </Stack>
            </div>
            <Stack direction="horizontal" gap={3}>
                <Button className="ms-auto w-50" onClick={handleClick}
                        disabled={barcode === null}>Next</Button>
            </Stack>
        </>
    )
}

export default Scan;
import React, {useEffect, useMemo} from "react"
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {Button, Container, Form, Row, Stack, Table} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import TableWithPages from "../../../components/TableWithPages";
import {ColumnDef} from "@tanstack/react-table";
import {getTubesByBarcodeIds} from "../../../firebase";
import Tube from "../../../models/Tube";
import Col from "react-bootstrap/Col";
import {searchBarcodes} from "../../../s-wipe-api";

const Admin = () => {

    const {apiToken, countDocuments, logOut} = useUserAuth();
    const navigate = useNavigate();
    // const [numBarcodes, setNumBarcodes] = React.useState<number>();
    // const [numUsers, setNumUsers] = React.useState<number>();
    const [tubes, setTubes] = React.useState<Array<Tube>>([]);
    const [tubeSearchText, setTubeSearchText] = React.useState<string>('');

    // countDocuments('barcodes')
    //     .then((count: number) => setNumBarcodes(count));
    //
    // countDocuments('users')
    //     .then((count: number) => setNumUsers(count));

    useEffect(() => {
        if (!!tubeSearchText && tubeSearchText.length > 2) {
            searchBarcodes(apiToken, tubeSearchText).then(barcodeIds => {
                getTubesByBarcodeIds(barcodeIds).then(result => setTubes(result));
            });
        } else {
            setTubes([])
        }

    }, [apiToken, tubeSearchText]);

    const tubeColumns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'tubeId',
            header: 'Tube'
        },
        {
            accessorKey: 'kitId',
            header: 'Kit',
        },
        {
            accessorKey: 'tubeNumber',
            header: 'Tube Number',
        },
        {
            accessorKey: 'mass',
            header: 'Mass',
        },
        {
            accessorKey: 'customerName',
            header: 'Customer Name',
        },
        {
            accessorKey: 'customerEmail',
            header: 'Customer Email',
        },
        {
            accessorKey: 'customerTracking',
            header: 'Customer Tracking',
        },
        {
            accessorKey: 'returnTracking',
            header: 'ReturnTracking',
        }
    ], []);

    if (!apiToken) {
        logOut().then(() => navigate("/login"));
        return;
    }

    return (
        <Stack className="m-1 gap-md-3 m-md-3" gap={1}>
            {/*<h5>Statistics</h5>*/}
            {/*<Table className="small table-sm">*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <th>Barcodes</th>*/}
            {/*        <td>{numBarcodes}</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <th>Users</th>*/}
            {/*        <td>{numUsers}</td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</Table>*/}

            <div className="mb-3">
                <Container>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <h5>Search S'wipe Tubes by barcode, customer info, or tracking info</h5>
                        </Col>
                        <Col md={4}>
                            <Form.Control type="text" placeholder="Input text" value={tubeSearchText}
                                          onChange={e => setTubeSearchText(e.target.value)}/>
                        </Col>
                    </Row>
                </Container>

                {!!tubeSearchText && tubeSearchText.length > 2 && (
                    <TableWithPages data={tubes} columns={tubeColumns} className="small"/>
                )}
            </div>

            <h5>Actions</h5>
            <Link to="/account/admin/scan"><Button>Verify Testing Kit</Button></Link>
            {/*/barcode*/}
            {/*<Link to="/account/admin/scan/qrcode"><Button>Test QRCode scanning</Button></Link>*/}
        </Stack>
    )
}

export default Admin;
import React from "react";
import {Button, Form, Modal, Stack, Table} from "react-bootstrap";

const TrackingNumberModal = (props: {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    assign: (customerTracking: string, returnTracking: string) => void;
}) => {

    const [customerTracking, setCustomerTracking] = React.useState<string>();
    const [returnTracking, setReturnTracking] = React.useState<string>();

    return (
        <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Tracking Numbers</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body className="small">
                    <Stack className="gap-md-3" gap={1}>
                        <Form.Control type="text" placeholder="Enter Customer Tracking Number"
                                      onChange={(e) =>
                                          setCustomerTracking(e.currentTarget.value)}/>
                        <Form.Control type="text" placeholder="Enter Return Tracking Number"
                                      onChange={(e) =>
                                          setReturnTracking(e.currentTarget.value)}/>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setShowModal(false)}>Cancel</Button>
                    <Button disabled={!customerTracking && !returnTracking} onClick={() => {
                        props.assign(customerTracking, returnTracking);
                        props.setShowModal(false);
                    }}>Assign</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default TrackingNumberModal;
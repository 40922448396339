import React, {useRef} from "react";
import Column from "../../../components/Column";
import BarcodeScanner from "../../../components/BarcodeScanner";
import {Button, Form, Stack, Table} from "react-bootstrap";
import {getBarcodeInfo, updateBarcodeInfo} from "../../../firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import StripeCustomersModal from "./StripeCustomersModal";
import {captureException} from "@sentry/react";
import TrackingNumberModal from "./TrackingNumberModal";

const TestScan = () => {  // {scanType}: {scanType: string}

    const scannedBarcode = useRef<string>();
    const [barcodeInfo, setBarcodeInfo] = React.useState<Array<{ [key: string]: any }>>();
    const [showCustomers, setShowCustomers] = React.useState<boolean>(false);
    const [showTracking, setShowTracking] = React.useState<boolean>(false);
    const [barcodeText, setBarcodeText] = React.useState<string>();

    const barcodeKeys: string[] = [];
    if (!!barcodeInfo) {
        for (const barcode of barcodeInfo) {
            for (const key in barcode) {
                if (!barcodeKeys.includes(key)) {
                    barcodeKeys.push(key);
                }
            }
        }
    }
    barcodeKeys.sort();

    function handleScan(barcode: string) {
        scannedBarcode.current = barcode;
        setBarcodeText(barcode);
        getBarcodeInfo(barcode)
            .then(barcodes => setBarcodeInfo(barcodes));
    }

    function checkAttribute(barcodes: Array<{ [key: string]: any }>, ...attributeNames: string[]) {
        let success = !!barcodes && barcodes.length > 0;
        for (const attributeName of attributeNames) {
            if (!barcodes.every(barcode =>
                barcode.hasOwnProperty(attributeName) && !!barcode[attributeName])) {
                success = false;
                break;
            }
        }
        return <FontAwesomeIcon icon={success ? faCheck : faXmark} size='lg' color={success ? 'green' : 'red'}/>
    }

    return (
        <>
            <StripeCustomersModal showModal={showCustomers} setShowModal={setShowCustomers}
                                  assign={(name: string, email: string) => {
                                      if (barcodeInfo.length > 0) {
                                          updateBarcodeInfo(barcodeInfo[0].testingKitId, {
                                              customerEmail: email,
                                              customerName: name
                                          }).then(() => {
                                              if (!!scannedBarcode.current) {
                                                  handleScan(scannedBarcode.current);
                                              }
                                          }).catch(err => {
                                              const msg = `Could not assign customer ${name} (${email}) to kit number ${barcodeInfo[0].testingKitId}:`;
                                              console.error(msg, err);
                                              captureException(msg + err);
                                          });
                                      }
                                  }}/>

            <TrackingNumberModal showModal={showTracking} setShowModal={setShowTracking}
                                 assign={(customerTracking: string, returnTracking: string) => {
                                     console.log(customerTracking, returnTracking);
                                     if (barcodeInfo.length > 0) {
                                         updateBarcodeInfo(barcodeInfo[0].testingKitId, {
                                             customerTracking: customerTracking,
                                             returnTracking: returnTracking
                                         }).then(() => {
                                             if (!!scannedBarcode.current) {
                                                 handleScan(scannedBarcode.current);
                                             }
                                         }).catch(err => {
                                             const msg = `Could not assign tracking numbers ${customerTracking} and ${returnTracking} to kit number ${barcodeInfo[0].testingKitId}:`;
                                             console.error(msg, err);
                                             captureException(msg + err);
                                         })
                                     }
                                 }}/>

            <Stack className="gap-md-3 py-1 py-md-3" gap={1}>
                <h3 className="text-center">Scan Kit Barcode to view its information</h3>
                <Column className="py-0">
                    <Stack className="gap-md-3" gap={1}>
                        <BarcodeScanner handleScan={handleScan}/>
                        <div className="flex-grow-1">
                            <Stack direction="horizontal" gap={1}>
                                <Form.Label className="small">Scan or enter the barcode manually</Form.Label>
                                <Form.Control value={barcodeText}
                                              onChange={(e) => {
                                                  handleScan(e.currentTarget.value);
                                                  // setBarcodeText(e.currentTarget.value);
                                              }}/>
                            </Stack>
                        </div>
                    </Stack>
                </Column>
                {!!barcodeInfo && (
                    <>
                        <div>
                            <h3 className="text-center">Kit Summary</h3>
                            <Table className="table-sm small">
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <td>Has tube numbers 1, 2, 3</td>
                                    <td>{checkAttribute(barcodeInfo, 'tubeNumber')}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Has tube mass values</td>
                                    <td>{checkAttribute(barcodeInfo, 'mass')}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Has customer info</td>
                                    <td>{checkAttribute(barcodeInfo, 'customerEmail', 'customerName')}</td>
                                    <td>
                                        <Button variant="secondary" size="sm" onClick={() => setShowCustomers(true)}>
                                            Assign customer
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Has tracking info</td>
                                    <td>{checkAttribute(barcodeInfo, 'customerTracking', 'returnTracking')}</td>
                                    <td>
                                        <Button variant="secondary" size="sm" onClick={() => setShowTracking(true)}>
                                            Assign tracking
                                        </Button>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <h3 className="text-center">Kit Details</h3>
                            <div className="overflow-x-scroll">
                                <Table className="table-sm small">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        {barcodeInfo.map(barcode => (
                                            <th key={barcode['barcode']}>{barcode['barcode']}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {barcodeKeys.map(key => (
                                        <tr key={key}>
                                            <th>{key}</th>
                                            {barcodeInfo.map((barcode, index) => (
                                                <td key={`${key}-${index}`}>{barcode[key]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </>
                )}
            </Stack>
        </>
    )
}

export default TestScan;
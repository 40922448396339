import React, {useState} from 'react';
import "react-barcode-scanner/polyfill"
import {Form, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {checkTestKitIsAvailable} from "../../../../firebase";
import StackWithButtons from "../../../../components/StackWithButtons";
import BarcodeScanner from "../../../../components/BarcodeScanner";


const ScanBarcode = (props: {
    prev: () => void;
    next: (x: string) => void;
}) => {

    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [barcode, setBarcode] = useState<string>("");
    const [barcodeText, setBarcodeText] = useState<string>("");

    function updateBarcode(text: string) {
        checkTestKitIsAvailable(text)
            .then((available) => {
                if (available) {
                    setBarcode(text);
                    setBarcodeText(text);
                    setShowSuccessMessage(true);
                }
            })
    }

    return (
        <>
            <StackWithButtons title="Scan a Barcode" prev={props.prev} next={() => props.next(barcode)}
                              isNextAvailable={barcode.length > 0}>
                <BarcodeScanner handleScan={(text) => {
                    if (!!text) {
                        updateBarcode(text);
                    }
                }}/>

                <h6 hidden={!showSuccessMessage}>
                    Barcode recognized successfully. Press <Link to="#" onClick={() => props.next(barcode)}>
                    Next</Link> to continue.
                </h6>

                <div className="flex-grow-1">
                    <Stack direction="horizontal" gap={1}>
                        <Form.Label className="small">Scan or enter the barcode manually</Form.Label>
                        <Form.Control value={barcodeText}
                                      onChange={(e) => {
                                          updateBarcode(e.currentTarget.value);
                                          setBarcodeText(e.currentTarget.value);
                                      }}/>
                    </Stack>
                </div>
            </StackWithButtons>
        </>
    )
}

export default ScanBarcode;
class Tube {
    tubeId: string;
    kitId: string;
    tubeNumber: number;
    mass: number;
    customerEmail: string | undefined;
    customerName: string | undefined;
    customerTracking: string | undefined;
    returnTracking: string | undefined;


    constructor(testId: string, kitId: string, tubeNumber: number, mass: number,
                customerName?: string, customerEmail?: string, customerTracking?: string, returnTracking?: string) {
        this.tubeId = testId;
        this.kitId = kitId;
        this.tubeNumber = tubeNumber;
        this.mass = mass;
        this.customerEmail = customerEmail;
        this.customerName = customerName;
        this.customerTracking = customerTracking;
        this.returnTracking = returnTracking;
    }
}

export default Tube;
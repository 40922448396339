import React, {useEffect, useState} from "react";
import {CameraDevice, Html5Qrcode, Html5QrcodeScanType, Html5QrcodeSupportedFormats} from "html5-qrcode";
import {captureException} from "@sentry/react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCameraRotate} from "@fortawesome/free-solid-svg-icons";

const BarcodeScanner = (props: {
    handleScan: (barcode: string) => void;
}) => {

    const [cameras, setCameras] = useState<Array<CameraDevice>>([]);
    const [cameraIndex, setCameraIndex] = useState(0);

    useEffect(() => {
        console.log('Supported constraints', navigator.mediaDevices.getSupportedConstraints());

        Html5Qrcode.getCameras().then(
            devices => {
                console.log(`Found ${devices ? devices.length : 0} cameras`);
                if (devices && devices.length > 0) {
                    setCameras(devices);
                    try {
                        for (let i = 0; i < devices.length; i++) {
                            const camera = devices[i];
                            if (camera.label?.toLowerCase().includes("back")) {
                                setCameraIndex(i);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                        captureException(err);
                    }
                }
            })
            .catch((error) => {
                console.error('Error when getting cameras', error.message);
                if (['NotAllowedError', 'AbortError', 'NotFoundError'].includes(error.name)) {
                    // if (error.name === 'NotAllowedError' || error.name === 'AbortError' || error.name === 'NotFoundError') {
                    alert('No camera can be accessed right now. Please refresh this page and allow camera access. ' +
                        'Alternatively, you can manually enter the barcode number below.');
                } else {
                    throw error;
                }
            })
    }, []);

    useEffect(() => {

        if (cameras.length === 0) return;

        console.log('Starting camera...', cameraIndex, cameras[cameraIndex].id);

        const container = document.getElementById('reader');
        if (!container) {
            throw new Error('HTML Element with id=reader not found');
        }
        container.innerHTML = '';
        const scanRegionId = createBasicLayout(container);

        const barcodeReader = new Html5Qrcode(scanRegionId, {
            formatsToSupport: [Html5QrcodeSupportedFormats.CODE_128],
            verbose: false
        });

        const configuration = {
            fps: 10,
            qrbox: (width: number, height: number) => {
                if (!width || !height) return {
                    width: 250,
                    height: 250
                };
                return {
                    width: 0.8 * width,
                    height: 0.8 * height
                }
            },
            facingMode: "environment",
            aspectRatio: 1.0,
            height: {min: 400, ideal: 720},
            width: {min: 400, ideal: 720},
            focusMode: "continuous",
            focusDistance: 0.0254,
            formatsToSupport: [Html5QrcodeSupportedFormats.CODE_128],
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
            advanced: [{zoom: 1.0}],
            experimentalFeatures: {useBarCodeDetectorIfSupported: false}
        };

        // @ts-ignore  Ignore the type mismatch of `configuration`
        barcodeReader.start(cameras[cameraIndex].id, configuration,
            (decodedText, decodedResult) => {
                if (decodedText !== null) {
                    props.handleScan(decodedText);
                }
            },
            (errorMessage, error) => {
                if (error.type !== 0) {
                    console.error('Error while scanning: ' + errorMessage);
                    captureException(error);
                    alert('Barcode scanning was interrupted. Please refresh this page and scan again.')
                }
            }
        ).catch((error) => {
            console.error('Error in html5qrcode.start: ' + error)
            captureException(error);
            alert('Cannot start the camera. Try to reset camera permissions and make sure that no other app is ' +
                'using the camera at the moment. If this does not help, you can manually enter the barcode number ' +
                'below');
        });

        return () => {
            const emptyHtmlContainer = () => {
                const mainContainer = document.getElementById('reader');
                if (mainContainer) {
                    mainContainer.innerHTML = "";
                }
            }

            if (barcodeReader.isScanning) {
                barcodeReader.stop().then(() => {
                    if (!barcodeReader) {
                        return;
                    }
                    // QR Code scanning is stopped.
                    barcodeReader.clear();
                    emptyHtmlContainer();

                }).catch((err) => {
                    // Stop failed, handle it.
                    console.error('Error in html5qrcode.stop: ' + err)
                });
            } else {
                barcodeReader.clear();
                emptyHtmlContainer();
            }

            // try {
            //     barcodeReader.clear();
            // } catch (error) {
            //     console.error('Error in html5qrcode.start: ' + error);
            // }


            // return () => {
            //     (async () => {
            //         if (barcodeReader.isScanning) {
            //             await barcodeReader.stop();
            //         }
            //         barcodeReader.clear();
            //     })();
        }
    }, [cameraIndex, cameras]);

    function changeCamera() {
        if (cameras === null) return;

        if (cameraIndex === null) {
            setCameraIndex(0);
        } else {
            setCameraIndex((cameraIndex + 1) % cameras.length);
        }
    }

    function createBasicLayout(parent: HTMLElement) {
        const scanRegion = document.createElement('div');
        const scanRegionId = `${parent.getAttribute('id')}-scan-region`;
        scanRegion.id = scanRegionId;
        scanRegion.style.width = '100%';
        scanRegion.style.minHeight = '250px';
        scanRegion.className = 'bg-dark';
        parent.appendChild(scanRegion);
        return scanRegionId;
    }

    return (
        <div className="position-relative">
            <div id='reader'>
                {/*<div>*/}
                    {/*<div id="reader" className="bg-dark" style={{width: '100%', minHeight: '250px'}}></div>*/}
                {/*</div>*/}
            </div>
            <div className="position-absolute top-0 px-1 text-center text-white small"
                 style={{width: '100%'}}>
                {cameras.length > 0 && cameraIndex !== null ? cameras[cameraIndex].label : ''}
            </div>
            <div className="position-absolute bottom-0 p-1 d-flex justify-content-center w-100">
                <Button className="py-0 px-3 border-2 rounded-0" variant="outline-light"
                        onClick={() => changeCamera()}>
                    <FontAwesomeIcon icon={faCameraRotate} className="me-1"/>
                    Change camera
                </Button>
            </div>
        </div>
    )
}

export default BarcodeScanner;
